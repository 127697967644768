import LoginPage from "./pages/login"
import ShotCasting from "./pages/shotCasting"
import Preview from "./pages/preview"


const routes = [
  { path: '/login', component: LoginPage, name:"login"},
  { path: '/preview/:production/:preview_id', component: Preview, name:"preview"},
  { path: '/casting/:production/:episode?/:sequence?/:shot?', component: ShotCasting, name: "shot_casting"},
]

export default routes