<template>
  <div id="preview">
    <img
      class="preview-img"
      :src="preview"
      :style="{ 'max-width': ratio + '%', 'max-height': ratio + '%' }"
    />
  </div>
</template>

<script>
import { getOriginalPreview } from "@/services/kitsu";

export default {
  name: "preview-page",
  data() {
    return {
      preview: "",
      ratio: Math.round(window.devicePixelRatio * 100),
    };
  },
  created() {
    this.getPrev();
    window.addEventListener("resize", () => {
      this.ratio = Math.round(window.devicePixelRatio * 100);
    });
  },
  methods: {
    async getPrev() {
      const previewID = this.$route.params.preview_id;
      const production = this.$route.params.production;
      this.preview = await getOriginalPreview(production, previewID);
    },
  },
};
</script>

<style>
.preview-img {
  min-height: 0px;
  min-width: 0px;
}

#app {
  overflow: auto;
  width: 100%;
  height: 100%;
}
</style>
