import {isAuthenticated, getZouInstances} from "@/services/kitsu"

async function authenticationGuard(to, from, next) {
    if (to.name === 'login') return next()
    const prod = to.query.production || to.params.production
    if (!prod || !Object.keys(getZouInstances()).includes(prod)) {
        return next({ name: "login" })
    } 
    const token = localStorage.getItem(`token-${prod}`)
    if (!token || !await isAuthenticated(prod, token)) {
        return next({ name: "login", query: { redirect: to.path, production: prod }})
    }
    next()
}

export default authenticationGuard