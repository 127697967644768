import { get_zou_servers } from "@/services/env.js"

const PROXY=process.env.VUE_APP_PROXY // To avoid CORS Policies with Zou
const ZOU_SERVERS=get_zou_servers()

function getZouInstances() {
    return ZOU_SERVERS
}

function getServerURL(production) { return ZOU_SERVERS[production].url }

/**
   * Connect user to Kitsu instance.
   * @param  {String} email      Credential email.
   * @param  {String} password   Credential password.
   * @return {String}            Authentication Token.
   * @throws Bad Request Error (400) if authentication failed.
*/
async function login(production, email, password) {
    const baseURL = getServerURL(production)
    const response = await fetch(
        `${PROXY}/${baseURL}/api/auth/login`, {
		method: "POST", 
		headers: {
            "Content-Type": "application/json" 
        },
		body: JSON.stringify({
            "email": email,
            "password": password,
        }) 
	})
    return (await response.json()).access_token
}

/**
   * Indicate if given token is valid, not out of date.
   * @param  {String} token    Kitsu token to test.
   * @return {Bool}            True if token is valid, else False.
*/
async function isAuthenticated(production, token) {
    const PERSON_NOT_FOUND = 401
    const UNPROCESSABLE_ENTITY = 422
    const baseURL = getServerURL(production)
    const response = await fetch(
        `${PROXY}/${baseURL}/api/auth/authenticated`, {
		method: "GET", 
		headers: {
            "Content-Type": "application/json",
            'Authorization': 'Bearer ' + token,  
        }
	})
    return  ![PERSON_NOT_FOUND, UNPROCESSABLE_ENTITY].includes(response.status)
}

/**
   * Get info from shot in the Kitsu instance.
   * Need to be logged to Kitsu
   * @param  {String} episode_name    Name of the episode containing the shot.
   * @param  {String} sequence_name   Name of the Sequence containing the shot.
   * @param  {String} shot_name       Name of the wanting shot.
   * @return {Object}                 Shot's information.
   * @throws Bad Request Error (400) if authentication failed.
*/
async function getShotInfo(production, shot) {
    const baseURL = getServerURL(production)
    const project = await getProject(production, baseURL)
    const casting = await getCasting(production, baseURL, project.id, shot.id)
    return { 
        casting: casting,
        brief: shot.data["brief-animation"] || "",
        note: shot.data["1_notes"] || ""
    }
}


async function getProject(production, baseURL) {
    const response0 = await fetch(
        `${PROXY}/${baseURL}/api/data/projects`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem(`token-${production}`),  
            },
        }
    )
    const projects = await response0.json()
    return projects.find(
        project => project.name.toLowerCase() === ZOU_SERVERS[production].projectName.toLowerCase()
    )
}


async function getAllEpisodes(production) {
    const baseURL = getServerURL(production)
    const project = await getProject(production, baseURL)
    const response = await fetch(
        `${PROXY}/${baseURL}/api/data/projects/${project.id}/episodes`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem(`token-${production}`),  
            },
        }
    )
    const episodes = await response.json()
    return episodes.map(e => { return { id: e.id, name: e.name } }).filter(e => e.name)
}

async function getAllSequences(production, episodeID) {
    const baseURL = getServerURL(production)
    const response = await fetch(
        `${PROXY}/${baseURL}/api/data/episodes/${episodeID}/sequences`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem(`token-${production}`),  
            },
        }
    )
    const sequences = await response.json()
    return sequences.map(e => { return { id: e.id, name: e.name } }).filter(e => e.name)
}

async function getAllShots(production, sequenceID) {
    const baseURL = getServerURL(production)
    const response = await fetch(
        `${PROXY}/${baseURL}/api/data/sequences/${sequenceID}/shots`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem(`token-${production}`),  
            },
        }
    )
    return await response.json()
}

async function getProjectImage(production,) {
    const baseURL = getServerURL(production)
    const project = await getProject(production, baseURL)
    const response = await fetch(
        `${PROXY}/${baseURL}/api/pictures/thumbnails/projects/${project.id}.png`, 
        {
            method: "GET", 
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem(`token-${production}`),
            }
        }
    )
    return URL.createObjectURL(await response.blob());
}

async function getCasting(production, baseURL, projectID, shotID) {
    const response = await fetch(
        `${PROXY}/${baseURL}/api/data/projects/${projectID}/entities/${shotID}/casting`,
        {
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem(`token-${production}`),  
            },
        }
    )
    return response.json()
}

/**
   * Get preview image of shot in it's uploaded resolution.
   * Need to be logged to Kitsu.
   * @param  {String} id    Kitsu id of the preview to download.
   * @return {String}       Url of the local download of th preview.
   * @throws Bad Request Error (400) if authentication failed.
*/
async function getOriginalPreview(production, id) {
    const baseURL = getServerURL(production)
    const response = await fetch(
        `${PROXY}/${baseURL}/api/pictures/originals/preview-files/${id}.png`, 
        {
            method: "GET", 
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem(`token-${production}`),  
            }
        }
    )
    return URL.createObjectURL(await response.blob());
}

/**
   * Get preview image of shot in it's thumbnail resolution.
   * Need to be logged to Kitsu.
   * @param  {String} id    Kitsu id of the preview to download.
   * @return {String}       Url of the local download of th preview.
   * @throws Bad Request Error (400) if authentication failed.
*/
async function getThumbnailPreview(production, id) {
    const baseURL = getServerURL(production)
    const response = await fetch(
        `${PROXY}/${baseURL}/api/pictures/previews/preview-files/${id}.png`, 
        {
            method: "GET", 
            headers: {
                "Content-Type": "application/json",
                'Authorization': 'Bearer ' + localStorage.getItem(`token-${production}`),  
            }
        }
    )
    return URL.createObjectURL(await response.blob());
}

export {
        getZouInstances, 
        login, 
        isAuthenticated, 
        getAllEpisodes, 
        getAllSequences,
        getAllShots,
        getShotInfo, 
        getOriginalPreview, 
        getThumbnailPreview,
        getProjectImage,
        getServerURL,
        getProject
    }