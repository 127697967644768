
function get_zou_servers() {
    let zou_servers = {}
    const env = Object.entries(process.env)
    const zou_vars = env.filter(varEnv => varEnv[0].startsWith("VUE_APP_ZOU_"));
    zou_vars.map(
        ([varEnv, url]) => {
            const prod = varEnv.replace("VUE_APP_ZOU_", "")
            const projectName = (prod.charAt(0) + prod.substring(1).toLowerCase()).replaceAll("_", " ")
            const slug = prod.toLowerCase().replaceAll("_", "-")
            zou_servers[slug] = {
                "projectName": projectName,
                "url": url
            }
        }
    )
    return zou_servers
}

export { get_zou_servers }