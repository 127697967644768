<template>
  <div v-if="brief || note" id="brief-bloc" class="ui segment">
    <div v-if="brief" id="brief-content">
      <h3><i class="file alternate orange icon"></i>Brief Animation</h3>
      <p id="brief">{{ brief }}</p>
    </div>
    <p v-if="note" id="note"><span>Notes : </span>{{ note }}</p>
  </div>
</template>

<script>
export default {
  name: "brief-segment",
  props: {
    brief: { type: String },
    note: { type: String },
  },
};
</script>

<style scoped>
#brief-bloc {
  max-width: 1000px;
  margin: auto;
  padding: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
  font-size: 16px;
  position: static;
}

#brief-content {
  margin-bottom: 10px;
}

#brief {
  font-style: italic;
}

#note span {
  font-weight: bold;
}
</style>
