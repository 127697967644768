<template>
  <section id="header-section">
    <img id="castview-logo" src="@/assets/castview.png" />
    <h1>Cast View</h1>
    <div id="header-options">
      <HeaderOptions
        :production="production"
        @updateCurrentShot="$emit('updateCurrentShot', $event)"
      />
    </div>
    <ProjectOptions :production="production" />
  </section>
</template>

<script>
import HeaderOptions from "@/components/headerOptions.vue";

import ProjectOptions from "@/components/projectOptions.vue";

export default {
  name: "header-section",
  components: {
    HeaderOptions,
    ProjectOptions,
  },
  props: {
    production: { type: String, require: true },
  },
};
</script>

<style scoped>
h1 {
  margin-top: 10px;
}

#header-section {
  padding-left: 20px;
  display: flex;
  align-items: center;
  position: fixed;
  width: 100%;
  height: 80px;
  background-color: white;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

#header-options {
  margin-left: 30px;
}

#castview-logo {
  margin: 10px;
  height: 60px;
}
</style>
