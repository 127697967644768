<template>
  <div id="shot-casting">
    <HeaderSection :production="production" @updateCurrentShot="setShot" />
    <section id="main-section">
      <ZoomOptions id="zoom" :zoomLevel.sync="zoomLevel" />
      <BriefSegment :note="note" :brief="brief" />
      <div v-for="(assets, asset_type) in sortedCasting" :key="asset_type">
        <div class="asset-type-bloc">
          <p class="asset-type-name">{{ asset_type }}</p>
        </div>
        <div class="asset-type-row">
          <AssetCard
            class="asset-card"
            :style="{ width: `${10 * zoomLevel}%` }"
            v-for="asset in assets"
            :key="asset.id"
            :asset="asset"
            :production="production"
          />
        </div>
      </div>
      <div v-if="casting.length == 0" id="no-casting-bloc">
        <h3>No casting to show</h3>
      </div>
    </section>
  </div>
</template>

<script>
import AssetCard from "@/components/assetCard";
import HeaderSection from "@/components/headerSection.vue";
import ZoomOptions from "@/components/zoomOptions.vue";
import BriefSegment from "@/components/briefSegment.vue";
import { getShotInfo } from "@/services/kitsu";

export default {
  name: "shot-casting",
  components: {
    AssetCard,
    ZoomOptions,
    HeaderSection,
    BriefSegment,
  },
  data() {
    return {
      production: this.$route.params.production,
      casting: [],
      brief: "",
      note: "",
      zoomLevel: localStorage.getItem("zoomLevel") || "5",
    };
  },
  watch: {
    zoomLevel: function (newZoomLevel) {
      localStorage.setItem("zoomLevel", newZoomLevel);
    },
  },
  computed: {
    sortedCasting: function () {
      const sortCasting = {};
      this.casting.forEach((asset) => {
        if (!sortCasting[asset.asset_type_name])
          sortCasting[asset.asset_type_name] = [];
        sortCasting[asset.asset_type_name].push(asset);
      });
      return sortCasting;
    },
  },
  methods: {
    async setShot(shot) {
      if (!shot) {
        this.casting = [];
        this.brief = "";
        this.note = "";
        return;
      }
      const shotInfo = await getShotInfo(this.production, shot);
      this.casting = shotInfo.casting;
      this.brief = shotInfo.brief;
      this.note = shotInfo.note;
    },
  },
};
</script>

<style scoped>
.asset-type-bloc {
  background-color: rgba(174, 174, 175, 0.15);
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 1px;
  box-shadow: 1px 1px 2px rgba(34, 36, 38, 0.15);
  width: 50px;
  text-align: center;
}

.asset-type-name {
  font-weight: bold;
  font-size: 18px;
}

.asset-type-row {
  display: flex;
  flex-flow: wrap;
  flex-direction: row;
}

#zoom {
  position: absolute;
  right: 22px;
  top: 100px;
  opacity: 60%;
}

#no-casting-bloc {
  text-align: center;
}

#main-section {
  padding: 50px;
  padding-top: 110px;
}
</style>
