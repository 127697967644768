<template>
  <div id="zoom-options">
    <i class="zoom-out icon" @click="zoom(-1)"></i>
    <input type="range" :min="zoomMin" :max="zoomMax" :step="step" v-model="privateZoomLevel"> 
    <i class="zoom-in icon" @click="zoom(1)"></i>
  </div>
</template>

<script>
export default {
  name: "zoom-options",
  props: {
    zoomLevel: {type: String, require: true},
    zoomMin: {type: String, default: "1"},
    zoomMax: {type: String, default: "10"},
    step: {type: String, default: "1"},
  },
  watch: {
    privateZoomLevel: function (newZoomLevel) {
        this.$emit("update:zoomLevel", newZoomLevel);
    }
  },
  data() {
    return {
        privateZoomLevel: this.zoomLevel
    }
  },
  methods: {
    zoom(zoomEffect) {
        const zoom = zoomEffect + parseInt(this.privateZoomLevel)
        if (zoom < this.zoomMin || zoom > this.zoomMax) return
        this.privateZoomLevel = zoom.toString()
    }
  }

};
</script>

<style scoped>
</style>
