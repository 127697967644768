<template>
  <div id="header-options">
    <div class="menu-element">
      <h3>Episode:</h3>
      <sui-dropdown
        class="dropdown"
        :options="episodesOptions"
        selection
        v-model="currentEpisodeID"
      />
    </div>

    <div class="menu-element" v-if="sequences.length != 0">
      <h3>Sequence:</h3>
      <sui-dropdown
        class="dropdown"
        :options="sequencesOptions"
        selection
        v-model="currentSequenceID"
      />
    </div>

    <div class="menu-element" v-if="shots.length != 0">
      <h3>Shot:</h3>
      <sui-dropdown
        class="dropdown"
        :options="shotsOptions"
        selection
        v-model="currentShotID"
      />
    </div>
    <div v-if="currentShotID">
      <a @click="openCastingPage"
        ><i class="external alternate large icon"></i
      ></a>
    </div>
  </div>
</template>

<script>
import {
  getAllEpisodes,
  getAllSequences,
  getAllShots,
  getServerURL,
  getProject,
} from "@/services/kitsu.js";

export default {
  name: "header-options",
  props: {
    production: { type: String, require: true },
  },
  watch: {
    currentEpisodeID: async function (newEpisodeID) {
      this.updateSequences(newEpisodeID);
    },
    currentSequenceID: async function (newSequenceID) {
      this.updateShots(newSequenceID);
    },
    currentShotID: async function (newShotID) {
      let shot = null;
      if (this.shots.length != 0)
        shot = this.shots.find((s) => s.id === newShotID);
      this.$emit("updateCurrentShot", shot);
    },
  },
  updated() {
    if (!this.isSet) return;
    let shot = null;
    let sequence = null;
    let episode = null;
    if (this.shots.length != 0)
      shot = this.shots.find((s) => s.id === this.currentShotID);
    if (this.sequences.length != 0)
      sequence = this.sequences.find((s) => s.id === this.currentSequenceID);
    if (this.episodes.length != 0)
      episode = this.episodes.find((s) => s.id === this.currentEpisodeID);
    if (
      shot?.name != this.$route.params.shot ||
      sequence?.name != this.$route.params.sequence ||
      episode?.name != this.$route.params.episode
    ) {
      this.$router
        .replace({
          params: {
            shot: shot?.name || null,
            sequence: sequence?.name || null,
            episode: episode?.name || null,
          },
        })
        .catch();
    }
  },
  computed: {
    episodesOptions() {
      if (!this.episodes) return [];
      return this.episodes.map((e) => {
        return { key: e.id, value: e.id, text: e.name };
      });
    },
    sequencesOptions() {
      if (!this.sequences) return [];
      return this.sequences.map((s) => {
        return { key: s.id, value: s.id, text: s.name };
      });
    },
    shotsOptions() {
      if (!this.shots) return [];
      return this.shots.map((s) => {
        return { key: s.id, value: s.id, text: s.name };
      });
    },
  },
  async created() {
    await this.updateEpisodes();
    const episode = this.episodes.find(
      (e) => e.name === this.$route.params.episode
    );
    const sequences = await this.updateSequences(episode?.id);
    const sequence = sequences.find(
      (s) => s.name === this.$route.params.sequence
    );
    const shots = await this.updateShots(sequence?.id);
    const shot = shots.find((s) => s.name === this.$route.params.shot);
    this.currentShotID = shot?.id;
    this.isSet = true;
  },
  methods: {
    async updateEpisodes() {
      this.shots = [];
      this.sequences = [];
      this.episodes = [];
      this.episodes = await getAllEpisodes(this.production);
    },
    async updateSequences(episodeID) {
      this.shots = [];
      this.sequences = [];
      this.currentEpisodeID = episodeID || "";
      this.currentSequenceID = "";
      if (episodeID)
        this.sequences = await getAllSequences(this.production, episodeID);
      return this.sequences;
    },
    async updateShots(sequenceID) {
      this.shots = [];
      this.currentSequenceID = sequenceID || "";
      this.currentShotID = "";
      if (sequenceID)
        this.shots = await getAllShots(this.production, sequenceID);
      return this.shots;
    },
    async openCastingPage() {
      const baseURL = getServerURL(this.production);
      const project = await getProject(this.production, baseURL);
      const url = `${baseURL}/productions/${project.id}/episodes/${this.currentEpisodeID}/shots/${this.currentShotID}?section=casting`;
      window.open(url, "_blank");
    },
  },
  data() {
    return {
      episodes: [],
      sequences: [],
      shots: [],
      isSet: false,
      currentEpisodeID: this.$route.params.episode,
      currentSequenceID: this.$route.params.sequence,
      currentShotID: this.$route.params.shot,
    };
  },
};
</script>

<style scoped>
h3 {
  margin: auto;
  margin-left: 15px;
}

.menu-element {
  display: flex;
}

.dropdown {
  margin: 10px;
  min-width: 130px !important;
  width: 130px;
}

#header-options {
  display: flex;
  align-items: center;
}
</style>
