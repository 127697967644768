<template>
  <div v-if="production" id="project">
    <h3 id="project-title">{{ production.toUpperCase() }}</h3>
    <div id="project-image">
      <img :src="productionImage" />
    </div>
  </div>
</template>

<script>
import { getProjectImage } from "@/services/kitsu";

export default {
  name: "brief-segment",
  props: {
    production: { type: String },
  },
  data() {
    return {
      productionImage: undefined,
    };
  },
  async created() {
    if (!this.production) return;
    this.productionImage = await getProjectImage(this.production);
  },
};
</script>

<style scoped>
#project {
  display: flex;
  position: absolute;
  right: 22px;
  align-items: center;
}

#project-title {
  margin: 0px;
}

#project-image img {
  border-radius: 50px;
  width: 52px;
  height: 52px;
  margin-left: 10px;
}
</style>
