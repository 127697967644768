<template>
  <div id="app">
    <div class="ui form">
      <div id="kitsu-logo-bloc">
        <img id="kitsu-logo" src="@/assets/kitsu-logo.png" />
      </div>
      <h2>Login to Kitsu</h2>
      <p id="auth-failed-message" v-if="authFail">
        Email or Password is incorrect.
      </p>
      <div class="field">
        <label>Production</label>
        <sui-dropdown
          class="dropdown"
          :options="prods_options"
          placeholder="Project"
          selection
          v-model="selectedProd"
        />
      </div>
      <div class="field">
        <label>Email</label>
        <input
          v-model="email"
          type="text"
          placeholder="f.name@xilam.com"
          @keyup.enter="login"
        />
      </div>
      <div class="field">
        <label>Password</label>
        <input v-model="password" type="password" @keyup.enter="login" />
      </div>
      <div id="options">
        <button
          id="connect-button"
          class="ui orange button"
          :disabled="!email || !password || !selectedProd"
          @click="login"
        >
          Connect
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { login, getZouInstances } from "@/services/kitsu";

export default {
  name: "login-page",
  data() {
    return {
      email: "",
      password: "",
      selectedProd: this.$route.query.production || "",
      authFail: false,
    };
  },
  computed: {
    prods_options() {
      let options = [];
      Object.entries(getZouInstances()).forEach(([slug, server]) => {
        options.push({ key: slug, value: slug, text: server.projectName });
      });
      return options;
    },
  },
  methods: {
    async login() {
      if (!this.email || !this.password || !this.selectedProd) return;
      const token = await login(this.selectedProd, this.email, this.password);
      if (!token) {
        this.authFail = true;
        return;
      }
      localStorage.setItem(`token-${this.selectedProd}`, token);
      if (this.$route.query.redirect) {
        this.$router.push({ path: this.$route.query.redirect });
      } else {
        this.$router.push({
          name: "shot_casting",
          params: { production: this.selectedProd },
        });
      }
    },
  },
};
</script>

<style scoped>
#auth-failed-message {
  color: rgb(247, 45, 45);
  font-weight: bold;
}

h2 {
  font-size: 22px;
  margin-bottom: 30px;
}

label {
  margin-bottom: 0px !important;
  padding-left: 6px;
  padding-right: 10px;
}

.field {
  display: flex;
  align-items: center;
}

.form {
  text-align: center;
  margin-top: 80px !important;
  margin: auto;
  width: 400px;
  padding: 40px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 6px;
  box-shadow: 2px 2px 5px rgba(34, 36, 38, 0.15);
}

#options {
  display: flex;
  flex-direction: row-reverse;
}

#connect-button {
  margin-right: 0px;
}

#kitsu-logo-bloc {
  margin: auto;
  margin-bottom: 30px;
  height: 140px;
}

#kitsu-logo {
  height: 100%;
}
</style>
