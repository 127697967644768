<template>
  <div id="app">
      <div>
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
