<template>
  <div id="asset-card">
    <router-link
      v-if="asset.preview_file_id"
      :to="{
        name: 'preview',
        params: { preview_id: asset.preview_file_id, production: production },
      }"
      target="_blank"
    >
      <img id="thumbnails" :src="thumbnailsURL" />
    </router-link>
    <img v-else id="thumbnails" src="@/assets/default-image.jpg" />
    <p id="asset-name">{{ asset.asset_name }}</p>
  </div>
</template>

<script>
import { getThumbnailPreview } from "@/services/kitsu";

export default {
  name: "asset-card",
  props: {
    production: String,
    asset: Object,
  },
  data() {
    return {
      thumbnailsURL: "",
    };
  },
  created() {
    this.getThumbnails();
  },
  methods: {
    async getThumbnails() {
      if (this.asset.preview_file_id) {
        this.thumbnailsURL = await getThumbnailPreview(
          this.production,
          this.asset.preview_file_id
        );
      }
    },
  },
};
</script>

<style scoped>
#thumbnails {
  height: 100%;
  width: 100%;
  border-radius: 5px;
}

#asset-name {
  padding-top: 8px;
  font-weight: bold;
  word-break: break-word;
}

#asset-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: fit-content;
  text-align: center;
  margin: 20px;
  padding: 12px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 6px;
  background-color: rgba(174, 174, 175, 0.15);
  box-shadow: 2px 2px 5px rgba(34, 36, 38, 0.15);
  transition: width 1s;
}
</style>
